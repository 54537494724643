/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Cursor from "./Cursor/cursor"
import Jello from "./Jello/jello"
import SEO from "./seo"
import Saver from "./saver"

import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
      <Cursor />
      <Jello />
      <Header siteTitle="test" />
      <SEO title="Home" />
      <Saver />
        <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
