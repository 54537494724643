/* eslint-disable */
import React, { useState, useEffect } from "react"

const Saver = () => {
    useEffect(() => {
        // Show an element
        var show = function (elem) {
            elem.style.display = 'block';
        };

        // Hide an element
        var hide = function (elem) {
            elem.style.display = 'none';
        };

        var timeout, videotimer;
        var checkState = function (event) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                saver('delay');
            // }, 2 * 60 * 1000);
            }, 1000000);
            // $('#saver').hide().html('');
            var saverElement = document.getElementById('saver');
            hide(saverElement);
            saverElement.innerHTML = '';
        };

   
        window.addEventListener('mousemove', checkState, false);
        window.addEventListener('keydown', checkState, false);
        window.addEventListener('click', checkState, false);
        window.addEventListener('touchstart', checkState, false);

        function saver(type){
            if(type != 'delay'){
                return;
            }

            var saverElement = document.getElementById('saver');
            show(saverElement);
            saverElement.innerHTML = '<canvas id="can"></canvas>';

            /* Set font styles */
            if(window.innerWidth > 1000){
                var i = "bold 150px 'Chakra Petch', Helvetica, Arial, sans-serif";
            }
            else {
                var i = "bold 50px 'Chakra Petch', Helvetica, Arial, sans-serif";
            }
            
            var n = 10;
            
            /* Set Copy */
            var e = "Kris Chase",

            /* Select canvas element */
            s = document.getElementById("can");
            var canvasO = s.getContext("2d");
            
            var o = window.innerWidth,
            a = window.innerHeight,
            l = 100,
            c = {
                x: Math.random() * o,
                y: Math.random() * a
            },
            d = 1,
            m = 1;
            s.width = o,
            s.height = a,
            canvasO.clearRect(0, 0, o, a),
            canvasO.beginPath();
            canvasO.font = i,
            canvasO.textBaseline = "top",
            canvasO.strokeStyle = 'yellow',
            canvasO.lineWidth = 1;
            var u = Math.ceil(canvasO.measureText(e).width),
            p = function (t, i) {
                t.fillText(e, i.x, i.y),
                    t.strokeText(e, i.x, i.y)
            },
            h = Date.now(),
            f = function () {
                function getRandomColor() {
                    // var items = [
                    //     'pink','blue', 'orange', 'hotpink', 'green', 'white'
                    // ];

                    var items = [
                        'green'
                    ];
            
                    // manually use _.random
                    var item = items[Math.floor(Math.random() * items.length)]
            
                    return item;
                }

                var color = getRandomColor();

                canvasO.strokeStyle = color,
                c.x + u >= window.innerWidth ? (d = -1,
                    c.x = window.innerWidth - (c.x + u - window.innerWidth) - u) : c.x <= 0 && (d = 1,
                    c.x = -c.x),
                    c.y + l >= window.innerHeight ? (m = -1,
                        c.y = window.innerHeight - (c.y + l - window.innerHeight) - l) : c.y <= 0 && (m = 1,
                        c.y = -c.y)
            },
            g = function e() {
                var i = Date.now(),
                    s = i - h;
                    s > 25 && (c.x += n * d,
                    c.y += n * m,
                    f(),
                    p(canvasO, c),
                    h = i),
                    window.raf = requestAnimationFrame(e)
            };

            window.raf = requestAnimationFrame(g)
        }
    });



    return (
        <div id="saver" style={{display: 'none'}}><canvas id="can" /></div>
    )
  };

  export default Saver;
