
import React, { useEffect } from "react"
import {Curtains} from 'curtainsjs';


const Jello = () => {

    useEffect(() => {
            // set up our WebGL context and append the canvas to our wrapper
            var footerWebGLCurtain = new Curtains({
                container: "footer-canvas",
                premultipliedAlpha: true,
                watchScroll: false,
                production: true // use the library in "production" mode 
            });
        
            // track the mouse positions to send it to the shaders
            var mousePosition = {
                x: 0,
                y: 0,
            };
        
            // if there's any error during init, we're going to catch it here
            footerWebGLCurtain.onError(function () {
                // we will add a class to the document body to display original images
                document.body.classList.add("no-curtains");
            }).onContextLost(function () {
                // on context lost, try to restore the context
                footerWebGLCurtain.restoreContext();
            });
            // get our plane element
            // var planeElement = document.getElementsByClassName("plane")[0];
        
            var forEach = function (array, callback, scope) {
                for (var i = 0; i < array.length; i++) {
                  callback.call(scope, i, array[i]); // passes back stuff we need
                }
              };

            // we will keep track of all our planes in an array
            // var footerPlanes = [];
            // var footerPlaneElements = document.getElementsByClassName("footer-plane");
            var hoverPlanes = [];
            // const hoverPlaneElements = document.getElementsByClassName("hover-plane");
            const hoverPlaneElements = document.querySelectorAll(".hover-plane");
            var hoverPlaneElement = '';

            // console.log(hoverPlaneElements);
            // console.log('about to loop, yo ');
            forEach(hoverPlaneElements, function (index, hoverPlaneElement) {

                // console.log('looping through hoverplaneelements');
                // console.log(hoverPlaneElement);
                
                hoverPlaneElement.insertAdjacentHTML('beforeend','<div id="hover-canvas-' + index + '"></div>');
    

                hoverPlaneElement.append('');
            });
        
            var hoverVs = `
                #ifdef GL_ES
                precision mediump float;
                #endif
        
                // those are the mandatory attributes that the lib sets
                attribute vec3 aVertexPosition;
                attribute vec2 aTextureCoord;
        
                // those are mandatory uniforms that the lib sets and that contain our model view and projection matrix
                uniform mat4 uMVMatrix;
                uniform mat4 uPMatrix;
        
            // our texture matrix uniform (this is the lib default name, but it could be changed)
            uniform mat4 uTextureMatrix0;
        
                // our time uniform
                uniform float uTime;
        
                // our mouse position uniform
                uniform vec2 uMousePosition;
        
                // our mouse strength
                uniform float uMouseStrength;
        
                // if you want to pass your vertex and texture coords to the fragment shader
                varying vec3 vVertexPosition;
                varying vec2 vTextureCoord;
        
                void main() {
                    vec3 vertexPosition = aVertexPosition;
            
                    // get the distance between our vertex and the mouse position
                    float distanceFromMouse = distance(uMousePosition, vec2(vertexPosition.x, vertexPosition.y));
            
                    // this will define how close the ripples will be from each other. The bigger the number, the more ripples you'll get
                    float rippleFactor = 8.0;
                    // calculate our ripple effect
                    float rippleEffect = cos(rippleFactor * (distanceFromMouse - (uTime / 50.0)));
            
                    // calculate our distortion effect
                    float distortionEffect = rippleEffect * uMouseStrength;
            
                    // apply it to our vertex position
                    vertexPosition +=  distortionEffect / 105.0;
            
                       gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
            
                    // varyings
            // thanks to the texture matrix we will be able to calculate accurate texture coords
            // so that our texture will always fit our plane without being distorted
                       vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
                       vVertexPosition = vertexPosition;
                }
            `;
        
            var hoverFs = `
                #ifdef GL_ES
                precision mediump float;
                #endif
        
                // get our varyings
                varying vec3 vVertexPosition;
                varying vec2 vTextureCoord;
        
                // our texture sampler (this is the lib default name, but it could be changed)
                uniform sampler2D uSampler0;
        
                void main() {
                    // get our texture coords
                    vec2 textureCoords = vTextureCoord;
        
                    // apply our texture
                    vec4 finalColor = texture2D(uSampler0, textureCoords);
        
        
                    // handling premultiplied alpha (useful if we were using a png with transparency)
                    finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);
        
                    gl_FragColor = finalColor;
                } 
            `;
        
            // set our initial parameters (basic uniforms)
        
            var params = {
                vertexShaderID: "plane-vs", // our vertex shader ID
                fragmentShaderID: "plane-fs", // our framgent shader ID
                widthSegments: 30,
                heightSegments: 30,
                drawCheckMargins: {
                    top: 100,
                    right: 0,
                    bottom: 100,
                    left: 0,
                },
                scale: {
                    x: 2,
                    y: 2
                },
                uniforms: {
                    time: {
                        name: "uTime", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    }
                }
            }
        
            var hoverParams = {
                vertexShader: hoverVs,
                fragmentShader: hoverFs,
                widthSegments: 20,
                heightSegments: 20, // we now have 20*20*6 = 2400 vertices !
                scale: {
                    x: 4,
                    y: 4
                },
                uniforms: {
                    time: {
                        name: "uTime", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    },
                    mousePosition: { // our mouse position
                        name: "uMousePosition",
                        type: "2f", // notice this is a length 2 array of floats
                        value: [mousePosition.x, mousePosition.y],
                    },
                    mouseStrength: { // the strength of the effect (we will attenuate it if the mouse stops moving)
                        name: "uMouseStrength", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    },
                }
            }
        
            // create our footer plane mesh
            // for (var i = 0; i < footerPlaneElements.length; i++) {
            //     footerPlanes.push(footerWebGLCurtain.addPlane(footerPlaneElements[i], params));
            //     handlePlanes(i);
            // }
        
            // handle basic plane
            // function handlePlanes(index) {
            //     var plane = footerPlanes[index];
        
            //     // check if our plane is defined and use it
            //     plane && plane.onRender(function () {
            //         // update the uniform
            //         plane.setPerspective(35);
            //         plane.uniforms.time.value++; // update our time uniform value
            //     });
            // }
        
            // handle the mouse move event
            function handleMovement(e, plane) {
        
                console.log(plane)
                if(plane){
                    // touch event
                    if (e.targetTouches) {
                        mousePosition.x = e.targetTouches[0].clientX;
                        mousePosition.y = e.targetTouches[0].clientY;
                    }
                    // mouse event
                    else {
                        mousePosition.x = e.clientX;
                        mousePosition.y = e.clientY;
                    }
            
                    // convert our mouse/touch position to coordinates relative to the vertices of the plane
                    var mouseCoords = plane.mouseToPlaneCoords(mousePosition.x, mousePosition.y);
                    // update our mouse position uniform
                    plane.uniforms.mousePosition.value = [mouseCoords.x, mouseCoords.y];
            
                    // reassign mouse strength
                    plane.uniforms.mouseStrength.value = 1;
                }
            }
        
            // add our hover planes and handle them
            hoverPlanes = [];

            for (var i = 0; i < hoverPlaneElements.length; i++) {

                var hoverWebGLCurtain = new Curtains({
                    container: "hover-canvas-" + i,
                    premultipliedAlpha: true,
                    antialias: false,
                    watchScroll: false,
                    production: true // use the library in "production" mode 
                    // autoResize: false
                });
        
                hoverWebGLCurtain.onError(function () {
                    // we will add a class to the document body to display original images
                    document.body.classList.add("no-curtains");
                }).onContextLost(function () {
                    // on context lost, try to restore the context
                    hoverWebGLCurtain.restoreContext();
                });
                footerWebGLCurtain.addPlane(hoverPlaneElements[i], hoverParams);
        
                hoverPlanes.push(footerWebGLCurtain.addPlane(hoverPlaneElements[i], hoverParams));
                
                let hoverCanvasElement = document.getElementById('hover-canvas-'+i);                
                hoverCanvasElement.firstChild.setAttribute("id", "canvas-"+i);;
                
                hoverPlanes.push(hoverWebGLCurtain.addPlane(hoverPlaneElements[i], hoverParams));
                handleHoverPlanes(i);
            }
        
            var hoverIndex = 0;
        
            forEach(hoverPlaneElements, function (index, hoverPlaneElement) {
                hoverPlaneElement.addEventListener('mouseenter', e => {
                    hoverIndex = index;
                });
            });
        
            // handle hover plane
            function handleHoverPlanes(index) {
                var hoverPlane = hoverPlanes[1];
                // check if our plane is defined and use it

                hoverPlane && hoverPlane.onRender(function () {
                    // update the uniform
                    hoverPlane.setPerspective(5)
                    hoverPlane.uniforms.time.value++;
        
                    // continually decrease mouse strength
                    hoverPlane.uniforms.mouseStrength.value = Math.max(0, hoverPlane.uniforms.mouseStrength.value - 0.0075);
                });
                
                var hoverIndex = 0;
                forEach(hoverPlaneElements, function (index, hoverPlaneElement) {
                    
                    hoverPlaneElement.addEventListener('mousemove', e => {
                        
                        // this was set to hoverIndex = 1...  But this should likely be dynamic depending on the index of the element you're hovering
                        handleMovement(e, hoverPlanes[1]);
                    })
                    hoverIndex++;
                });
        
        
            }
        
        
    });


    return (
        <div id="jello" style={{display: 'none'}}></div>
    )
};

  export default Jello;