
import React, { useEffect } from "react"
import { TweenMax } from "gsap";

const Cursor = () => {


// const theBall = document.getElementsByClassName('.theBall');

    useEffect(() => {
        var mouseX;
        var mouseY;
        //Custom cursor
        
            window.addEventListener('mousemove', e => {
                if (window.screen.width >= 1024) {
                    if (e.x === undefined || e.y === undefined) {
                        e.x = 0;
                        e.y = 0;
                    }
                    mouseX = e.x + 3;
                    mouseY = e.y + 3;
                    TweenMax.to('.theBall', 0, {left: mouseX, top: mouseY});
                    TweenMax.to('.theBall-1', 0, {left: mouseX, top: mouseY, delay: 0.15});
                    TweenMax.to('.theBall-2', 0, {left: mouseX, top: mouseY, delay: 0.3});
                }
            });
        
    
        //Cursor links
        
            const links = document.querySelectorAll("a, button");
            var theBall = document.getElementsByClassName("theBall");

            links.forEach(link => {
                // console.log(link);
                link.addEventListener('mouseenter', e => {
                    theBall.item(0).classList.add('zoom_rand2');
                });
                link.addEventListener('mouseleave', e => {
                    theBall.item(0).classList.remove("zoom_rand2");
                });
            });
    });
    

 
 
    // var classes = ["zoom_rand1", "zoom_rand2"];
    // Random cursor
    // $(document).on('mouseenter', '.side_nav_menu a, .films_filter a, .footer_links a, .contact_col a, .audio_links a, .control-container button, .audio_player_links a, .audio_waveform wave, .audio_meta_data, .hide_playlist, .audio_item_waveform', function () {
    //     theBall.addClass('zoom_rand1');
    // }).on('mouseleave', '.side_nav_menu a, .films_filter a, .footer_links a, .contact_col a, .audio_links a, .control-container button, .audio_player_links a, .audio_waveform wave, .audio_meta_data, .hide_playlist, .audio_item_waveform', function () {
    //     $(".theBall").removeClass('zoom_rand1 zoom_rand2 zoom_rand3');
 
    // $(document).on('mouseenter', '[data-cursor]', function () {
    //     $('.theBall').addClass('zooming_lg').find('span').text($(this).attr('data-cursor'));
    // }).on('mouseleave', '[data-cursor]', function () {
    //     $('.theBall').removeClass('zooming_lg').find('span').text('');
    // });

    return (
        <span>
            <div className="theBall"><span /></div>
            <div className="theBall theBall-1" />
            <div className="theBall theBall-2" />
        </span>
    )
}

export default Cursor;